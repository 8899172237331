import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import { faRedo } from "@fortawesome/free-solid-svg-icons"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styled from 'styled-components';

export const query = graphql`
  query {
    fv:file(relativePath: {eq: "fv.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
        original {
          src
          height
          width
        }
      }
    }
    about:file(relativePath: {eq: "about.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    categoryImg:file(relativePath: {eq: "book.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    allContentfulBlogPost(sort: {fields: updatedAt, order: DESC}, skip: 0, limit: 3) {
      edges {
        node {
          title
          id
          eyecatch {                        
              fluid(maxWidth: 573) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
          }
          slug
          publishDate
          publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
          updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
          updatedAt
          category {
            categorySlug
            category
            id
          }
        }
      }
    }
    bigcate:allContentfulCategory(filter: {navadd: {eq: "add"}}, sort: {fields: createdAt, order: ASC}) {
      edges {
          node {
            category
            id
            categorySlug
            blogpost {
              title
              slug
              publishDate
              publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
              updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
              updatedAt
            }
            thumbnail {
              fluid(maxWidth: 500) {
                  ...GatsbyContentfulFluid_withWebp
              }
            }
            categoryS1
            categoryS2
            categoryS3
            categoryS4
        }
      }
    }
    all:allContentfulCategory(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          category
          id
          categorySlug
          blogpost {
            id
            title
            slug
            publishDate
            publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
            updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
            updatedAt
          }
          thumbnail {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const AccordionStyle = styled(Accordion)`
    box-shadow: none!important;
    .MuiButtonBase-root,
    .MuiAccordionDetails-root {
        padding: 0!important;
    }
    .MuiAccordionSummary-content {
        margin: 0!important;
    }
`

export default ({ data }) => {

  return <div className="homepage">
    <SEO
      pagetitle="TOP"
      image={data.fv.childImageSharp.original.src}
      imageW={data.fv.childImageSharp.original.width}
      imageH={data.fv.childImageSharp.original.height}
    />
    <Layout>
      <section className="hero">
        <figure>
          <Img fluid={data.fv.childImageSharp.fluid} style={{ height: "100%" }} alt="" />
        </figure>
        <div className="catch">
          <p>
            What is fashion? Kleshas? Lookism?<br />
            Should we abandon fashion?<br />
            The power of fashion that makes even evil look beautiful.<br />
            The power of fashion that makes justice even more attractive.<br />
            What if you could use that power?
          </p>
          <h1>
            ファッションとは何でしょう？ 煩悩？ ルッキズム？<br />
            私たちはファッションを捨てるべき？<br />
            悪さえも美しく見せるファッションの力。<br />
            正義をさらに魅力的にするファッションの力。<br />
            あなたがその力を使うことができたらどうしますか？
          </h1>
        </div>

        <div className="wave custom-shape-divider-bottom-1610414979">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div>
        <div className="wave custom-shape-divider-bottom-1610415262">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>

      </section>

      <section className="food">
        <div className="container">
          <h2 className="bar l"><p className="en"><i>About </i><span>this website</span></p><p>このサイトについて</p></h2>
          <p>ファッションに関連する情報をメモし、公開するサイトです...</p>
          <Link to={`/about/`}>詳細はこちら<ChevronRightIcon /></Link>
        </div>
      </section>

      <section className="photo">
        <p className="sr-only">Photo</p>
        <figure>
          <Img fluid={data.about.childImageSharp.fluid} style={{ height: "100%" }} alt="" />
        </figure>
      </section>

      <section className="content categorylist">
        <div className="container">

          {data.bigcate.edges.map(bigcate => (<>
            <div className="bigcate">
              <h2 className="bar l"><p className="en"><span>about </span><i>{bigcate.node.categorySlug}</i></p><p>{bigcate.node.category}</p></h2>
              <div className="wrapper">
                <p className="topTitle"><Link to={`/${bigcate.node.categorySlug}`}>{bigcate.node.category} TOP<ChevronRightIcon /></Link></p>
                <ul>
                  {bigcate.node.blogpost.map(catlower => (
                    <li key={catlower.id}><Link to={`/undefined/${catlower.slug}`}>
                      <Img fluid={bigcate.node.thumbnail.fluid} style={{ height: "100%" }} alt={bigcate.node.category} />
                      <div className="inner">
                        <span>{catlower.title}</span>
                        <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                        <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                      </div>
                      <ChevronRightIcon />
                    </Link></li>
                  ))}
                </ul>
              </div>
            </div>

            <ul className="smallcate">
              {data.all.edges.map(allcate => (<>
                {bigcate.node.categoryS1 === allcate.node.categorySlug && (<li key={allcate.node.id}>
                  <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary>
                      <h3 className="bar2">{allcate.node.category}<KeyboardArrowDownIcon /></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wrapper">
                        <p className="topTitle"><Link to={`/${allcate.node.categorySlug}`}>{allcate.node.category} TOP<ChevronRightIcon /></Link></p>
                        <ul>
                          {allcate.node.blogpost.map(catlower => (
                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}`}>
                              <Img fluid={allcate.node.thumbnail.fluid} style={{ height: "100%" }} alt={allcate.node.category} />
                              <div className="inner">
                                <span>{catlower.title}</span>
                                <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                              </div>
                              <ChevronRightIcon />
                            </Link></li>
                          ))}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </AccordionStyle>
                </li>)}
                {bigcate.node.categoryS2 === allcate.node.categorySlug && (<li key={allcate.node.id}>
                  <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary>
                      <h3 className="bar2">{allcate.node.category}<KeyboardArrowDownIcon /></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wrapper">
                        <p className="topTitle"><Link to={`/${allcate.node.categorySlug}`}>{allcate.node.category} TOP<ChevronRightIcon /></Link></p>
                        <ul>
                          {allcate.node.blogpost.map(catlower => (
                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}`}>
                              <Img fluid={allcate.node.thumbnail.fluid} style={{ height: "100%" }} alt={allcate.node.category} />
                              <div className="inner">
                                <span>{catlower.title}</span>
                                <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                              </div>
                              <ChevronRightIcon />
                            </Link></li>
                          ))}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </AccordionStyle>
                </li>)}
                {bigcate.node.categoryS3 === allcate.node.categorySlug && (<li key={allcate.node.id}>
                  <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary>
                      <h3 className="bar2">{allcate.node.category}<KeyboardArrowDownIcon /></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wrapper">
                        <p className="topTitle"><Link to={`/${allcate.node.categorySlug}`}>{allcate.node.category} TOP<ChevronRightIcon /></Link></p>
                        <ul>
                          {allcate.node.blogpost.map(catlower => (
                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}`}>
                              <Img fluid={allcate.node.thumbnail.fluid} style={{ height: "100%" }} alt={allcate.node.category} />
                              <div className="inner">
                                <span>{catlower.title}</span>
                                <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                              </div>
                              <ChevronRightIcon />
                            </Link></li>
                          ))}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </AccordionStyle>
                </li>)}
                {bigcate.node.categoryS4 === allcate.node.categorySlug && (<li key={allcate.node.id}>
                  <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary>
                      <h3 className="bar2">{allcate.node.category}<KeyboardArrowDownIcon /></h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wrapper">
                        <p className="topTitle"><Link to={`/${allcate.node.categorySlug}`}>{allcate.node.category} TOP<ChevronRightIcon /></Link></p>
                        <ul>
                          {allcate.node.blogpost.map(catlower => (
                            <li key={catlower.id}><Link to={`/undefined/${catlower.slug}`}>
                              <Img fluid={allcate.node.thumbnail.fluid} style={{ height: "100%" }} alt={allcate.node.category} />
                              <div className="inner">
                                <span>{catlower.title}</span>
                                <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                              </div>
                              <ChevronRightIcon />
                            </Link></li>
                          ))}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </AccordionStyle>
                </li>)}

              </>))}
            </ul>
          </>))}

        </div>
      </section>

      <section className="photo">
        <p className="sr-only">Photo</p>
        <figure>
          <Img fluid={data.categoryImg.childImageSharp.fluid} style={{ height: "100%" }} alt="" />
        </figure>
      </section>

      <section className="content bloglist">
        <div className="container">
          <h2 className="bar l"><p className="en"><span>about </span><i>news</i></p><p>新着記事</p></h2>

          <div className="posts">
            {data.allContentfulBlogPost.edges.map(blog => (
              <article className="post" key={blog.node.id}>
                <Link to={`/undefined/${blog.node.slug}`}>
                  <figure>
                    <Img fluid={blog.node.eyecatch.fluid} style={{ height: "100%" }} alt={blog.node.eyecatch.description} />
                  </figure>
                  <p className="title">{blog.node.title}</p>
                </Link>
                <time dateTime={blog.node.publishDate}><FontAwesomeIcon icon={faClock} />{blog.node.publishDateJp}</time>
                <time dateTime={blog.node.updatedAt}><FontAwesomeIcon icon={faRedo} />{blog.node.updatedAtJp}</time>
                {blog.node.category && (
                  <div className="cat">
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <ul>
                      {blog.node.category.map(cat => (
                        <li className={cat.categorySlug} key={cat.id}>
                          <Link to={`/${cat.categorySlug}/`}>
                            {cat.category}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </article>
            ))}
          </div>

        </div>
      </section>
    </Layout>
  </div>
}
